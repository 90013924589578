@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/ag-grid/styles/ag-grid.css";
@import "./@vex/styles/ag-grid/styles/ag-theme-alpine.css";
@import "./@vex/styles/ag-grid/styles/ag-theme-alpine-dark.css";
@import "./@vex/styles/core";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

.item-detail-input-section {
  width: 14.2em;
  font-size: 12px;
  word-break: break-all;
}

.firstTab_item-detail-input-section {
  width: 18rem;
  font-size: 12px;
  word-break: break-all;
}

.item-detail-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 12px;
  white-space: nowrap;
  gap: 10px;
  padding-left: 1rem;
}
@media only screen and (min-width: 950px) {
  .item-detail-div-divider-firstTap {
    width: 50%;
    margin-top: 6%;
  }
  .item-detail-div-divider {
    width: 35%;
  }
  .item-detail-div {
    display: flex;
  }
  .item-detail-card {
    display: flex;
    flex-direction: row;
  }
  .image-viewer {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 1%;
  }
  .tracking {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .image-mat {
    max-width: 100% !important;
  }
  .item-detail-div-split {
    width: unset;
  }
}

@media only screen and (max-width: 950px) {
  .item-detail-div-divider-firstTap {
    width: unset;
    align-self: center;
  }
  .item-detail-div-divider {
    width: unset;
    align-self: center;
  }
  .item-detail-div {
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-items: flex-end;
  }
  .item-detail-top-div {
    margin-top: 15%;
  }
  .item-detail-card {
    display: unset;
  }
  .image-viewer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .tracking {
    word-break: break-word;
    display: flex;
    flex-direction: column;
  }
  .image-mat {
    max-width: 100% !important;
    margin-bottom: 2%;
  }
  .item-detail-div-split {
    width: min-content;
  }
}

.item-detail-img-card {
  margin-right: 1%;
}

.chat-div-account_circle {
  display: flex;
}
.second-account_circle {
  color: black;
  margin-top: -1em;
  margin-left: -1em;
  z-index: 1;
}
.first-account_circle {
  color: black;
  margin-top: -1em;
  margin-right: -1em;
  z-index: 1;
}

.chat {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.second {
  align-items: flex-start;
}

.second .message {
  max-width: calc(100% - 50%);
  word-wrap: break-word;
  margin-left: 0.5em;
  background-color: #eee;
  position: relative;
}

.second .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 21px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.second .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 21px;
  background: white;
  border-bottom-right-radius: 10px;
}

.first {
  align-items: flex-end;
}

.first .message {
  max-width: calc(100% - 50%);
  word-wrap: break-word;
  color: white;
  margin-right: 0.5em;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  position: relative;
}

.first .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 21px;
  width: 20px;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.first .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 21px;
  background: white;
  border-bottom-left-radius: 10px;
}

tr.element-row:not(.expanded-row):hover {
  background: whitesmoke;
}

tr.element-row:not(.expanded-row):active {
  background: #efefef;
}
.container {
  position: relative;
}

.table-container {
  position: relative;
  min-height: 300px;
  max-height: calc(100em - 45em);
  overflow: auto;
}

table {
  width: 100%;
}
.splide__slide img {
  width: 100%;
}
.splide__slid {
  display: none;
}

.splide__slide.is-active {
  opacity: 1;
}
.customSplideClass div {
  align-items: center;
}

.lb-loader {
  display: none;
}
FIELDSET {
  margin: 8px;
  border: 1px solid silver;
  padding: 8px;
  border-radius: 4px;
}
LEGEND {
  padding: 2px;
}
.headers-align .mat-expansion-panel-header-title,
.headers-align .mat-expansion-panel-header-description {
  flex-basis: 0;
}

.headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

.headers-align .mat-form-field + .mat-form-field {
  margin-left: 8px;
}

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
//:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
//}

.mat-table {
  width: 100%;
  overflow: auto;
}

.mat-table mat-cell,
.mat-table mat-footer-cell,
.mat-table mat-header-cell {
  width: 185px;
  flex: 1;
}

.mat-table mat-cell:first-child {
  padding-left: 0px;
  border-left: 1px solid;
}

.mat-table mat-cell:last-child {
  padding-right: 0px;
}

.mat-table mat-header-cell:first-child {
  padding-left: 0px;
  border-left: 1px solid;
}

.mat-table mat-header-cell:last-child {
  padding-right: 0px;
}

.mat-table mat-header-cell {
  border-right: 1px solid;
  cursor: col-resize;
}
.mat-table mat-header-row {
  border-top: 1px solid !important;
  border-left: 1px solid !important;
  border-bottom: 1px solid !important;
}
.mat-table td.mat-cell {
  border-bottom-color: unset;
  border-bottom: unset;
}
.mat-table mat-row,
.mat-table mat-header-row,
.mat-table mat-footer-row {
  justify-content: space-evenly;
}
.mat-table mat-cell,
.mat-table mat-footer-cell,
.mat-table mat-header-cell {
  justify-content: flex-start !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: rgba(var(--color-primary), 1) !important;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background: rgba(var(--color-primary), 1) !important;
}

.mat-dialog-container {
  /* add your styles */
  padding: 0px !important;
}
// .ag-row-group.ag-row-level-1 {
//   background-color: #faebd7;
// }

.mat-select-panel .mat-optgroup-label {
  height: unset !important;
}

.ag-header-cell-label {
  justify-content: center !important;
}
.ag-row {
  text-align: center !important;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  border: inherit !important;
}

.ag-overlay-wrapper
{
  margin-top: 1.58%;
}

.ag-header-cell-filtered {
  background-color: #78909c  !important;
  color: #fff !important;
}

.ag-header-cell-filtered span {
  color: #fff !important;
}

.responded{
  background-color: #00800075!important;
}

mat-expansion-panel.sortable-drag .mat-expansion-panel-content {
  display: none;
}
